const REGIONS = [
  { name: "Sector 1", id: 1 },
  { name: "Sector 2", id: 2 },
  { name: "Sector 3", id: 3 },
  { name: "Sector 4", id: 4 },
  { name: "Sector 5", id: 5 },
  { name: "Sector 6", id: 6 },
  { name: "Alba", id: 7 },
  { name: "Arad", id: 8 },
  { name: "Arges", id: 9 },
  { name: "Bacau", id: 10 },
  { name: "Bihor", id: 11 },
  { name: "Bistrita-Nasaud", id: 12 },
  { name: "Botosani", id: 13 },
  { name: "Brasov", id: 14 },
  { name: "Braila", id: 15 },
  { name: "Bucuresti", id: 16 },
  { name: "Buzau", id: 17 },
  { name: "Caras-Severin", id: 18 },
  { name: "Calarasi", id: 19 },
  { name: "Cluj", id: 20 },
  { name: "Constanta", id: 21 },
  { name: "Covasna", id: 22 },
  { name: "Dambovita", id: 23 },
  { name: "Dolj", id: 24 },
  { name: "Galati", id: 25 },
  { name: "Giurgiu", id: 26 },
  { name: "Gorj", id: 27 },
  { name: "Harghita", id: 28 },
  { name: "Hunedoara", id: 29 },
  { name: "Ialomita", id: 30 },
  { name: "Iasi", id: 31 },
  { name: "Ilfov", id: 32 },
  { name: "Maramures", id: 33 },
  { name: "Mehedinti", id: 34 },
  { name: "Mures", id: 35 },
  { name: "Neamt", id: 36 },
  { name: "Olt", id: 37 },
  { name: "Prahova", id: 38 },
  { name: "Salaj", id: 39 },
  { name: "Satu Mare", id: 40 },
  { name: "Sibiu", id: 41 },
  { name: "Suceava", id: 42 },
  { name: "Teleorman", id: 43 },
  { name: "Timis", id: 44 },
  { name: "Tulcea", id: 45 },
  { name: "Valcea", id: 46 },
  { name: "Vaslui", id: 47 },
  { name: "Vrancea", id: 48 },
  { name: "Strainatate", id: 49 },
];
const COUNTRIES = [
  { name: "Romania", id: 1 },
  { name: "Afganistan", id: 2 },
  { name: "Africa de Sud", id: 3 },
  { name: "Albania", id: 4 },
  { name: "Algeria", id: 5 },
  { name: "Andorra", id: 6 },
  { name: "Angola", id: 7 },
  { name: "Anguilla", id: 8 },
  { name: "Antarctica", id: 9 },
  { name: "Antigua si Barbuda", id: 10 },
  { name: "Antilele Olandeze", id: 11 },
  { name: "Arabia Saudita", id: 12 },
  { name: "Argentina", id: 13 },
  { name: "Armenia", id: 14 },
  { name: "Aruba", id: 15 },
  { name: "Atolul Johnston", id: 16 },
  { name: "Atolul Palmyra", id: 17 },
  { name: "Australia", id: 18 },
  { name: "Austria", id: 19 },
  { name: "Azerbaijan", id: 20 },
  { name: "Bahamas", id: 21 },
  { name: "Bahrain", id: 22 },
  { name: "Bangladesh", id: 23 },
  { name: "Barbados", id: 24 },
  { name: "Bassas da India", id: 25 },
  { name: "Belarus", id: 26 },
  { name: "Belgia", id: 27 },
  { name: "Belize", id: 28 },
  { name: "Benin", id: 29 },
  { name: "Bermuda", id: 30 },
  { name: "Birmania", id: 31 },
  { name: "Bhutan", id: 32 },
  { name: "Bolivia", id: 33 },
  { name: "Bosnia si Hertegovina", id: 34 },
  { name: "Botswana", id: 35 },
  { name: "Brazilia", id: 36 },
  { name: "British Indian Ocean Territory", id: 37 },
  { name: "Brunei", id: 38 },
  { name: "Bulgaria", id: 39 },
  { name: "Burkina Faso", id: 40 },
  { name: "Burundi", id: 41 },
  { name: "Cambodgia", id: 42 },
  { name: "Camerun", id: 43 },
  { name: "Canada", id: 44 },
  { name: "Cape Verde", id: 45 },
  { name: "Cehia Chad", id: 46 },
  { name: "Chile", id: 47 },
  { name: "China", id: 48 },
  { name: "Cipru", id: 49 },
  { name: "Coasta de Fildes", id: 50 },
  { name: "Columbia", id: 51 },
  { name: "Comoros", id: 52 },
  { name: "Coreea de Nord", id: 53 },
  { name: "Coreea de Sud", id: 54 },
  { name: "Costa Rica", id: 55 },
  { name: "Croatia", id: 56 },
  { name: "Cuba", id: 57 },
  { name: "Danemarca", id: 58 },
  { name: "Djibouti", id: 59 },
  { name: "Dominica", id: 60 },
  { name: "Ecuador", id: 61 },
  { name: "Egipt", id: 62 },
  { name: "Elvetia", id: 63 },
  { name: "El Salvador", id: 64 },
  { name: "Emiratele Arabe Unite", id: 65 },
  { name: "Eritrea", id: 66 },
  { name: "Estonia", id: 67 },
  { name: "Etiopia", id: 68 },
  { name: "Fiji", id: 69 },
  { name: "Filipine", id: 70 },
  { name: "Finlanda", id: 71 },
  { name: "Franta", id: 72 },
  { name: "Gabon", id: 73 },
  { name: "Gambia", id: 74 },
  { name: "Georgia", id: 75 },
  { name: "Georgia de Sud si Insulele Sandwich de Sud", id: 76 },
  { name: "Germania", id: 77 },
  { name: "Ghana", id: 78 },
  { name: "Gibraltar", id: 79 },
  { name: "Grecia", id: 80 },
  { name: "Greenland", id: 81 },
  { name: "Grenada", id: 82 },
  { name: "Guadeloupe", id: 83 },
  { name: "Guam", id: 84 },
  { name: "Guatemala", id: 85 },
  { name: "Guernsey", id: 86 },
  { name: "Guineea", id: 87 },
  { name: "Guineea-Bissau", id: 88 },
  { name: "Guineea Ecuatoriala", id: 89 },
  { name: "Guyana Guyana Franceza", id: 90 },
  { name: "Haiti", id: 91 },
  { name: "Honduras", id: 92 },
  { name: "Hong Kong", id: 93 },
  { name: "Islanda", id: 94 },
  { name: "India", id: 95 },
  { name: "Indonezia", id: 96 },
  { name: "Insulele Ashmore si Cartier", id: 97 },
  { name: "Insula Baker", id: 98 },
  { name: "Insula Bouvet", id: 99 },
  { name: "Insula Jarvis", id: 100 },
  { name: "Insula Clipperton", id: 101 },
  { name: "Insula Craciun", id: 102 },
  { name: "Insula Europa", id: 103 },
  { name: "Insula Juan de Nova", id: 104 },
  { name: "Insulele Cayman", id: 105 },
  { name: "Insulele Cocos (Keeling)", id: 106 },
  { name: "Insulele Cook", id: 107 },
  { name: "Insulele Faroe", id: 108 },
  { name: "Insulele Glorioso", id: 109 },
  { name: "Insula Heard si Insulele McDonald", id: 110 },
  { name: "Insula Howland", id: 111 },
  { name: "Insula Wake", id: 112 },
  { name: "Insulele Malvine", id: 113 },
  { name: "Insulele Marea de Corali", id: 114 },
  { name: "Insulele Mariana de Nord", id: 115 },
  { name: "Insulele Marshall", id: 116 },
  { name: "Insulele Midway", id: 117 },
  { name: "Insula Navassa", id: 118 },
  { name: "Insulele Paracel", id: 119 },
  { name: "Insulele Pitcairn", id: 120 },
  { name: "Insulele Solomon", id: 121 },
  { name: "Insulele Spratly", id: 122 },
  { name: "Insula Tromelin", id: 123 },
  { name: "Insulele Turks si Caicos", id: 124 },
  { name: "Insulele Virgine", id: 125 },
  { name: "Insulele Virgine Britanice", id: 126 },
  { name: "Iran", id: 127 },
  { name: "Irak", id: 128 },
  { name: "Irlanda", id: 129 },
  { name: "Isle of Man", id: 130 },
  { name: "Israel", id: 131 },
  { name: "Italia", id: 132 },
  { name: "Jamaica", id: 133 },
  { name: "Jan Mayen", id: 134 },
  { name: "Japonia", id: 135 },
  { name: "Jersey", id: 136 },
  { name: "Jordan", id: 137 },
  { name: "Kazakhstan", id: 138 },
  { name: "Kenya", id: 139 },
  { name: "Kiribati", id: 140 },
  { name: "Kuwait", id: 141 },
  { name: "Kyrgyzstan", id: 142 },
  { name: "Laos", id: 143 },
  { name: "Lesoto", id: 144 },
  { name: "Letonia", id: 145 },
  { name: "Liberia", id: 146 },
  { name: "Liban", id: 147 },
  { name: "Libia", id: 148 },
  { name: "Liechtenstein", id: 149 },
  { name: "Lituania", id: 150 },
  { name: "Luxemburg", id: 151 },
  { name: "Macau", id: 152 },
  { name: "Macedonia", id: 153 },
  { name: "Madagascar", id: 154 },
  { name: "Malawi", id: 155 },
  { name: "Malaezia", id: 156 },
  { name: "Maldive", id: 157 },
  { name: "Mali", id: 158 },
  { name: "Malta", id: 159 },
  { name: "Martinique", id: 160 },
  { name: "Mauritania", id: 161 },
  { name: "Mauritius", id: 162 },
  { name: "Maroc", id: 163 },
  { name: "Mayotte", id: 164 },
  { name: "Mexic", id: 165 },
  { name: "Monaco", id: 166 },
  { name: "Mongolia", id: 167 },
  { name: "Montserrat", id: 168 },
  { name: "Mozambic", id: 169 },
  { name: "Muntenegru", id: 170 },
  { name: "Namibia", id: 171 },
  { name: "Nauru", id: 172 },
  { name: "Nepal", id: 173 },
  { name: "New Caledonia", id: 174 },
  { name: "New Zealand", id: 175 },
  { name: "Nicaragua", id: 176 },
  { name: "Niger", id: 177 },
  { name: "Nigeria", id: 178 },
  { name: "Niue", id: 179 },
  { name: "Norvegia", id: 180 },
  { name: "Olanda", id: 181 },
  { name: "Oman", id: 182 },
  { name: "Pakistan", id: 183 },
  { name: "Palau", id: 184 },
  { name: "Panama", id: 185 },
  { name: "Papua Noua Guinee", id: 186 },
  { name: "Paraguay", id: 187 },
  { name: "Peru", id: 188 },
  { name: "Polinezia Franceza", id: 189 },
  { name: "Polonia", id: 190 },
  { name: "Portugalia", id: 191 },
  { name: "Puerto Rico", id: 192 },
  { name: "Qatar", id: 193 },
  { name: "Reciful Kingman", id: 194 },
  { name: "Regatul Unit", id: 195 },
  { name: "Republica Centra-Africana", id: 196 },
  { name: "Republica Congo", id: 197 },
  { name: "Republica Democrata Congo", id: 198 },
  { name: "Republica Dominicana", id: 199 },
  { name: "Republica Moldova", id: 200 },
  { name: "Reunion", id: 201 },
  { name: "Rusia", id: 202 },
  { name: "Ruanda", id: 203 },
  { name: "Sfanta Helena", id: 204 },
  { name: "Sfantul Kitts si Nevis", id: 205 },
  { name: "Sfanta Lucia", id: 206 },
  { name: "Sfantul Pierre si Miquelon", id: 207 },
  { name: "Sfantul Vincent si Grenadinele", id: 208 },
  { name: "Sahara Vestica Samoa", id: 209 },
  { name: "Samoa Americana", id: 210 },
  { name: "San Marino", id: 211 },
  { name: "Sao Tome si Principe", id: 212 },
  { name: "Senegal", id: 213 },
  { name: "Serbia", id: 214 },
  { name: "Seychelles", id: 215 },
  { name: "Sierra Leone", id: 216 },
  { name: "Siria Singapore", id: 217 },
  { name: "Slovacia", id: 218 },
  { name: "Slovenia", id: 219 },
  { name: "Somalia", id: 220 },
  { name: "Spania", id: 221 },
  { name: "Sri Lanka", id: 222 },
  { name: "Statele Federate ale Microneziei", id: 223 },
  { name: "Statele Unite ale Americii", id: 224 },
  { name: "Stramtoarea Gaza", id: 225 },
  { name: "Sudan", id: 226 },
  { name: "Suedia", id: 227 },
  { name: "Suriname", id: 228 },
  { name: "Svalbard", id: 229 },
  { name: "Swaziland", id: 230 },
  { name: "Tailanda", id: 231 },
  { name: "Taiwan", id: 232 },
  { name: "Tajikistan", id: 233 },
  { name: "Tanzania", id: 234 },
  { name: "Teritoriile Sudice si Antarctice Franceze Timorul de Est", id: 235 },
  { name: "Togo", id: 236 },
  { name: "Tokelau", id: 237 },
  { name: "Tonga", id: 238 },
  { name: "Trinidad si Tobago", id: 239 },
  { name: "Tunisia", id: 240 },
  { name: "Turcia", id: 241 },
  { name: "Turkmenistan", id: 242 },
  { name: "Tuvalu", id: 243 },
  { name: "Ucraina", id: 244 },
  { name: "Uguanda", id: 245 },
  { name: "Ungaria", id: 246 },
  { name: "Uruguay", id: 247 },
  { name: "Uzbekistan", id: 248 },
  { name: "Vanuatu", id: 249 },
  { name: "Vatican", id: 250 },
  { name: "Venezuela", id: 251 },
  { name: "Vietnam", id: 252 },
  { name: "Wallis si Futuna", id: 253 },
  { name: "West Bank", id: 254 },
  { name: "Yemen", id: 255 },
  { name: "Zambia", id: 256 },
  { name: "Zimbabwe", id: 257 },
];

export { REGIONS, COUNTRIES };
