import Header from "../components/Header";
import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { getCheckInSummary, patientCheckIn } from "../api/General";
import CancelModal from "../components/CancelModal";
import { useState } from "react";
import Loading from "../components/Loading";
import moment from "moment-timezone";
import PhoneInput from "react-phone-input-2";
import { REGIONS, COUNTRIES } from "../utils/Constants";
import { toast } from "react-toastify";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/20/solid";

const Confirm = ({ lang }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState({});
  const [cancelled, setCancelled] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmationValid, setConfirmationValid] = useState(false);
  const [showGdpr, setShowGdpr] = useState(false);
  const [patientData, setPatientData] = useState({
    code: "",
    email: "",
    phone: "",
    area: "",
    mediul: "",
    area_id: "",
    country: "",
    country_id: "",
    city: "",
    address: "",
    countryCode: "ro",
  });

  useEffect(() => {
    getCheckInSummary(id).then((res) => {
      if (res.status === 404) {
        setNotFound(true);
        setLoading(false);
        return;
      }
      setBooking(res.data);
      setLoading(false);
    });
  }, []);

  const onConfirm = () => {
    var contacts_data = [];

    if (
      booking?.missing_information?.email === false &&
      patientData.email !== ""
    ) {
      contacts_data.push({
        pacient_id: booking?.booking_data?.pacient_id,
        data: patientData.email,
        data_type: "email",
        subscribe: 1,
        obs: "",
      });
    }
    if (
      booking?.missing_information?.phone === false &&
      patientData.phone !== ""
    ) {
      contacts_data.push({
        pacient_id: booking?.booking_data?.pacient_id,
        data: patientData.phone,
        data_type: "phone",
        subscribe: 1,
        obs: "",
      });
    }

    const savedDate = {
      uuid: id,
      pacient_id: booking?.booking_data?.pacient_id,
      pacient_data: {
        code:
          booking?.missing_information?.code === false
            ? patientData.code
            : undefined,
        mediu:
          booking?.missing_information?.mediu === false
            ? parseInt(patientData.mediul)
            : undefined,
        area:
          booking?.missing_information?.area === false ||
          booking?.missing_information?.area_id === false
            ? patientData.area
            : undefined,
        area_id:
          booking?.missing_information?.area_id === false ||
          booking?.missing_information?.area === false
            ? patientData.area_id
            : undefined,
        country:
          booking?.missing_information?.country === false ||
          booking?.missing_information?.country_id === false
            ? patientData.country
            : undefined,
        country_id:
          booking?.missing_information?.country_id === false ||
          booking?.missing_information?.country === false
            ? patientData.country_id
            : undefined,
        city:
          booking?.missing_information?.city === false
            ? patientData.city
            : undefined,
        address:
          booking?.missing_information?.address === false
            ? patientData.address
            : undefined,
      },
      contacts_data: contacts_data,
    };

    const res = patientCheckIn(savedDate).then((res) => {
      if (res.status === 200) {
        toast.success(
          lang === "ro"
            ? "Programarea a fost confirmată cu succes"
            : "Appointment confirmed successfully",
          {
            style: {
              width: "300px",
            },
          }
        );
        setConfirmationValid(true);
      } else {
        toast.error(
          lang === "ro"
            ? "A apărut o eroare la confirmarea programării"
            : "An error occurred while confirming the appointment",
          {
            style: {
              width: "300px",
            },
          }
        );
      }
    });
    toast.promise(
      res,
      {
        pending:
          lang === "ro"
            ? "Se confirma programarea..."
            : "Confirming appointment...",
      },
      { style: { width: "300px" } }
    );
  };
  return (
    <>
      <Header lang={lang} sidebar={false} />
      <CancelModal
        lang={lang}
        open={open}
        setOpen={setOpen}
        booking={booking}
      />
      <div>
        <main className=" mt-16">
          <div className="px-4 sm:px-6 lg:px-8 py-20">
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm align-center text-center">
                {loading === true ? (
                  <div className="text-center mx-auto flex justify-center">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {notFound === true ? (
                      <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                        {lang === "ro"
                          ? "Programarea nu a fost găsită"
                          : "Appointment not found"}
                      </h3>
                    ) : confirmationValid === true ? (
                      <>
                        <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                          {lang === "ro"
                            ? "Programarea a fost confirmată cu succes"
                            : "Appointment confirmed successfully"}
                        </h3>
                        <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                          {lang === "ro" ? (
                            <span>
                              Vă așteptăm în data de{" "}
                              {moment
                                .utc(booking?.booking_data?.programat)
                                .tz("Europe/Bucharest")
                                .locale("ro")
                                .format("dddd, DD MMMM YYYY, [ora] HH:mm")}
                            </span>
                          ) : (
                            <span>
                              We are waiting for you on{" "}
                              {moment
                                .utc(booking?.booking_data?.programat)
                                .tz("Europe/Bucharest")
                                .locale("en")
                                .format("dddd, DD MMMM YYYY, [at] HH:mm")}
                            </span>
                          )}
                        </h3>
                      </>
                    ) : (
                      <>
                        {booking?.booking_data.confirmed === 1 ? (
                          <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                            {lang === "ro"
                              ? "Programarea a fost deja confirmată"
                              : "Appointment already confirmed"}
                          </h3>
                        ) : (
                          <>
                            <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                              {lang === "ro"
                                ? "Confirmare programare"
                                : "Appointment confirmation"}
                            </h3>
                            <div className="text-xl text-center my-10">
                              {lang === "ro" ? (
                                <>
                                  {Object.keys(booking?.missing_information)
                                    .length !== 0 ? (
                                    <>
                                      <span>
                                        Vă rugăm să completați datele de mai jos
                                        pentru a confirma programarea din data
                                        de{" "}
                                      </span>
                                      <strong>
                                        {moment
                                          .utc(booking?.booking_data?.programat)
                                          .tz("Europe/Bucharest")
                                          .locale("ro")
                                          .format(
                                            "dddd, DD MMMM YYYY, [ora] HH:mm"
                                          )}
                                      </strong>
                                      , pe numele{" "}
                                      <strong>
                                        {
                                          booking?.booking_data
                                            ?.pacient_first_name
                                        }{" "}
                                        {
                                          booking?.booking_data
                                            ?.pacient_last_name
                                        }
                                      </strong>
                                      <>
                                        <p
                                          className="text-start mt-6 text-sm underline cursor-pointer"
                                          onClick={() => setShowGdpr(!showGdpr)}
                                        >
                                          {showGdpr === true ? (
                                            <span>Ascunde</span>
                                          ) : (
                                            <span>Afișează</span>
                                          )}{" "}
                                          detalii despre prelucrarea datelor{" "}
                                          {showGdpr === true ? (
                                            <ChevronDownIcon className="h-5 w-5 inline transform rotate-180 " />
                                          ) : (
                                            <ChevronDownIcon className="h-5 w-5 inline " />
                                          )}
                                        </p>
                                        <p
                                          className={
                                            (showGdpr === true
                                              ? ""
                                              : "hidden") +
                                            " text-xs mt-2 text-justify lg:text-center"
                                          }
                                        >
                                          Vă rugăm să introduceţi datele
                                          pacientului pentru care se face
                                          programarea. Toate datele dvs. sunt
                                          confidențiale, securizate și
                                          protejate. Datele personale sunt
                                          prelucrate conform REGULAMENTULUI (UE)
                                          2016/679 AL PARLAMENTULUI EUROPEAN ȘI
                                          AL CONSILIULUI din 27 aprilie 2016
                                          privind protecția persoanelor fizice
                                          în ceea ce privește prelucrarea
                                          datelor cu caracter personal, precum
                                          și a legilor române în vigoare. Datele
                                          pot fi dezvăluite unor terţi în baza
                                          unui temei legal justificat. Vă puteţi
                                          exercita drepturile menționate în{" "}
                                          <a
                                            href="https://infosan.ro/politica-de-confidentialitate/"
                                            target="_blank"
                                            className="text-blue-500"
                                          >
                                            {" "}
                                            Politica noastră de
                                            confidențialitate
                                          </a>{" "}
                                          în condiţiile prevăzute de
                                          Regulamentului (UE) 2016/679 al
                                          Parlamentului European și al
                                          Consiliului din 27 aprilie 2016
                                          privind protecția persoanelor fizice
                                          în ceea ce privește prelucrarea
                                          datelor cu caracter personal ("GDPR")
                                          și Legea nr. 190 din 18 iulie 2018
                                          privind măsuri de punere în aplicare a
                                          Regulamentului (UE) 2016/679 al
                                          Parlamentului European și al
                                          Consiliului din 27 aprilie 2016
                                          privind protecția persoanelor fizice
                                          în ceea ce privește prelucrarea
                                          datelor cu caracter personal și
                                          privind libera circulație a acestor
                                          date și de abrogare a Directivei
                                          95/46/CE (Regulamentul general privind
                                          protecția datelor), printr-o cerere
                                          scrisă, trimisă la oricare din datele
                                          de contact ale Societății, prevăzute
                                          în Secțiunea 2 din{" "}
                                          <a
                                            href="https://infosan.ro/politica-de-confidentialitate/"
                                            target="_blank"
                                            className="text-blue-500"
                                          >
                                            {" "}
                                            Politica noastră de
                                            confidențialitate.
                                          </a>
                                        </p>
                                      </>
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        Vă rugăm să confirmați programarea din
                                        data de{" "}
                                      </span>
                                      <strong>
                                        {moment
                                          .utc(booking?.booking_data?.programat)
                                          .tz("Europe/Bucharest")
                                          .locale("ro")
                                          .format(
                                            "dddd, DD MMMM YYYY, [ora] HH:mm"
                                          )}
                                      </strong>
                                      , pe numele{" "}
                                      <strong>
                                        {
                                          booking?.booking_data
                                            ?.pacient_first_name
                                        }{" "}
                                        {
                                          booking?.booking_data
                                            ?.pacient_last_name
                                        }
                                      </strong>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {Object.keys(booking?.missing_information)
                                    .length !== 0 ? (
                                    <>
                                      <span>
                                        Please complete the data below to
                                        confirm the appointment on{" "}
                                      </span>
                                      <strong>
                                        {moment
                                          .utc(booking?.booking_data?.programat)
                                          .tz("Europe/Bucharest")
                                          .locale("ro")
                                          .format(
                                            "dddd, DD MMMM YYYY, [ora] HH:mm"
                                          )}
                                      </strong>
                                      , on behalf of{" "}
                                      <strong>
                                        {
                                          booking?.booking_data
                                            ?.pacient_first_name
                                        }{" "}
                                        {
                                          booking?.booking_data
                                            ?.pacient_last_name
                                        }
                                      </strong>
                                      <>
                                        <p
                                          className="text-start mt-6 text-sm underline cursor-pointer"
                                          onClick={() => setShowGdpr(!showGdpr)}
                                        >
                                          {showGdpr === true ? (
                                            <span>Hide</span>
                                          ) : (
                                            <span>Show</span>
                                          )}{" "}
                                          details about data processing{" "}
                                          {showGdpr === true ? (
                                            <ChevronDownIcon className="h-5 w-5 inline transform rotate-180 " />
                                          ) : (
                                            <ChevronDownIcon className="h-5 w-5 inline " />
                                          )}
                                        </p>
                                        <p
                                          className={
                                            (showGdpr === true
                                              ? ""
                                              : "hidden") +
                                            " text-xs mt-2 text-justify lg:text-center"
                                          }
                                        >
                                          Personal data is processed in
                                          accordance with Regulation (EU)
                                          2016/679 of the European Parliament
                                          and of the Council of 27 April 2016 on
                                          the protection of individuals with
                                          regard to the processing of personal
                                          data and the protection of individuals
                                          with regard to the processing of
                                          personal data with respect to the
                                          processing of personal data, as well
                                          as of the Romanian laws in force, the
                                          data may be disclosed to third parties
                                          on a reasonably justified basis. You
                                          can exercise the rights mentioned in
                                          our{" "}
                                          <a
                                            href="https://infosan.ro/politica-de-confidentialitate/"
                                            target="_blank"
                                            className="text-blue-500"
                                          >
                                            {" "}
                                            Privacy Policy
                                          </a>{" "}
                                          under the conditions provided by
                                          Regulation (EU) 2016/679 of the
                                          European Parliament and of the Council
                                          of 27 April 2016 on the protection of
                                          natural persons with regard to the
                                          processing of personal data ("GDPR")
                                          and Law no. 190 of 18 July 2018 on
                                          measures to implement Regulation (EU)
                                          2016/679 of the European Parliament
                                          and of the Council of 27 April 2016 on
                                          the protection of natural persons with
                                          regard to the processing of personal
                                          data and on the free movement of such
                                          data and repealing Directive 95/46/EC
                                          (General Data Protection Regulation),
                                          through a written request sent to any
                                          of the contact details of the Company,
                                          provided in Section 2 of our{" "}
                                          <a
                                            href="https://infosan.ro/politica-de-confidentialitate/"
                                            target="_blank"
                                            className="text-blue-500"
                                          >
                                            {" "}
                                            Privacy Policy
                                          </a>
                                          .
                                        </p>
                                      </>
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        Please confirm the appointment on{" "}
                                      </span>
                                      <strong>
                                        {moment
                                          .utc(booking?.booking_data?.programat)
                                          .tz("Europe/Bucharest")
                                          .locale("ro")
                                          .format(
                                            "dddd, DD MMMM YYYY, [ora] HH:mm"
                                          )}
                                      </strong>
                                      , on behalf of{" "}
                                      <strong>
                                        {
                                          booking?.booking_data
                                            ?.pacient_first_name
                                        }{" "}
                                        {
                                          booking?.booking_data
                                            ?.pacient_last_name
                                        }
                                      </strong>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="w-full ml-0 mb-10">
                              <div className="grid grid-cols-9 gap-2 lg:gap-4 mb-10">
                                {booking?.missing_information?.code ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="cnp"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        CNP:
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <input
                                        type="text"
                                        id="cnp"
                                        name="cnp"
                                        value={patientData.code}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            code: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.email ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="email"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        E-mail:
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        required
                                        value={patientData.email}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            email: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.phone ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="phone"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>{" "}
                                        {lang === "ro"
                                          ? "Telefon:"
                                          : "Mobile Phone:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 w-full lg:w-4/5">
                                      <PhoneInput
                                        country={"ro"}
                                        inputStyle={{
                                          width: "100%",
                                          height: "34px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px",
                                        }}
                                        preferredCountries={["ro"]}
                                        countryCodeEditable={false}
                                        inputProps={{
                                          name: "phone",
                                          required: true,
                                        }}
                                        value={patientData.phone}
                                        onChange={(
                                          value,
                                          country,
                                          e,
                                          formattedValue
                                        ) => {
                                          setPatientData({
                                            ...patientData,
                                            phone: value,
                                            countryCode: country.countryCode,
                                          });
                                        }}
                                      />
                                      {patientData.countryCode === "ro" &&
                                      patientData.phone?.match(/\d/g)?.length >
                                        11 ? (
                                        <>
                                          <p className="text-start">
                                            <span className="text-red-600 align-text-bottom 	">
                                              <XCircleIcon className="h-4 w-4 inline-block" />
                                            </span>
                                            <span className="text-red-600">
                                              {lang === "ro"
                                                ? "Numărul de telefon introdus este incorect. Acesta trebuie să conțină exact 9 cifre, în afară de prefixul internațional (+40)."
                                                : "The phone number you entered is incorrect. It must contain exactly 9 digits, excluding the international prefix (+40)."}
                                            </span>
                                          </p>
                                        </>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.area === false ||
                                booking?.missing_information?.area_id ===
                                  false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="area_id"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>{" "}
                                        {lang === "ro"
                                          ? "Județ / Sector:"
                                          : "County / Sector:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <select
                                        id="area_id"
                                        name="area_id"
                                        value={patientData.area_id}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            area_id: parseInt(e.target.value),
                                            area: e.target.options[
                                              e.target.selectedIndex
                                            ].text,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      >
                                        <option value="" hidden>
                                          Selectează
                                        </option>
                                        {REGIONS.map((region) => {
                                          return (
                                            <option
                                              value={region.id}
                                              key={region.id}
                                            >
                                              {region.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.mediu ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="mediu"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        {lang === "ro" ? "Mediu:" : "City:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <select
                                        id="mediu"
                                        name="mediu"
                                        value={patientData.mediul}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            mediul: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      >
                                        <option value="" hidden>
                                          Selectează
                                        </option>
                                        <option value="1">Urban</option>
                                        <option value="2">Rural</option>
                                      </select>
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.country ===
                                  false ||
                                booking?.missing_information?.country_id ===
                                  false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="country_id"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>{" "}
                                        {lang === "ro" ? "Țară:" : "Country:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <select
                                        id="country_id"
                                        name="country_id"
                                        value={patientData.country_id}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            country_id: parseInt(
                                              e.target.value
                                            ),
                                            country:
                                              e.target.options[
                                                e.target.selectedIndex
                                              ].text,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      >
                                        <option value="" hidden>
                                          Selectează
                                        </option>
                                        {COUNTRIES.map((country) => {
                                          return (
                                            <option
                                              value={country.id}
                                              key={country.id}
                                            >
                                              {country.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.city ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="city"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        {lang === "ro"
                                          ? "Localitate:"
                                          : "City:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <input
                                        type="city"
                                        id="city"
                                        name="city"
                                        required
                                        value={patientData.city}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            city: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.address ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="address"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        {lang === "ro" ? "Adresă:" : "Address:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <input
                                        type="address"
                                        id="address"
                                        name="address"
                                        required
                                        value={patientData.address}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            address: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <table className="w-full">
                                <thead className="bg-[#f7f4f4]">
                                  <tr>
                                    <th
                                      colSpan={3}
                                      className="text-left p-1.5 border border-[#ccc] text-sm font-semibold"
                                    >
                                      {lang === "ro"
                                        ? "DESCRIERE"
                                        : "DESCRIPTION"}
                                    </th>
                                    <th className="text-left p-1.5 border border-[#ccc] text-sm font-semibold">
                                      {lang === "ro" ? "PRET" : "PRICE"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="text-left p-2  border border-[#ccc]"
                                    >
                                      <span className="font-semibold">
                                        {
                                          booking?.booking_data?.prices?.package
                                            ?.name
                                        }
                                      </span>
                                      <br></br>
                                      <span>
                                        Doctor:{" "}
                                        {booking?.booking_data?.user_first_name}{" "}
                                        {booking?.booking_data?.user_last_name}
                                      </span>
                                    </td>
                                    <td className="p-2 border border-[#ccc] text-left ">
                                      {parseInt(
                                        booking?.booking_data?.prices?.package
                                          ?.price
                                      )}{" "}
                                      lei
                                    </td>
                                  </tr>
                                  {booking?.booking_data?.prices?.manevre
                                    ?.length > 0 ? (
                                    <>
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className="text-left p-1.5 border border-[#ccc] text-sm font-semibold bg-[#f7f4f4]"
                                        >
                                          {lang === "ro"
                                            ? "INVESTIGATII"
                                            : "INVESTIGATIONS"}
                                        </td>
                                      </tr>
                                      {booking?.booking_data?.prices?.manevre.map(
                                        (man) => {
                                          return (
                                            <tr>
                                              <td
                                                colSpan={3}
                                                className="text-left p-2  border border-[#ccc]"
                                              >
                                                <span className="font-semibold">
                                                  {man.name}
                                                </span>
                                              </td>
                                              <td className="p-2 border border-[#ccc] text-left ">
                                                {parseInt(man.price)} lei
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                  <tr className="bg-[#fffcce]">
                                    <td
                                      className="text-right font-semibold p-2 border border-[#ccc]"
                                      colSpan={3}
                                    >
                                      TOTAL
                                    </td>
                                    <td className="font-semibold p-2 border border-[#ccc] text-left">
                                      {parseInt(
                                        booking?.booking_data?.prices?.total
                                      )}{" "}
                                      lei
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <button
                              type="button"
                              onClick={onConfirm}
                              className="align-center mx-auto bg-secondary hover:bg-primary text-white px-4 py-1.5 rounded text-2xl "
                            >
                              {lang === "ro"
                                ? "Confirmă programarea"
                                : "Confirm appointment"}
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Confirm;
