import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import SidebarDesktop from "../components/SidebarDesktop";
import { purgeDoctors } from "../features/doctors/DoctorsSlice";
import { purgePackages } from "../features/packages/PackagesSlice";
import { purgeSlots } from "../features/slots/SlotsSlice";
import { purgePersonalInformation } from "../features/personalInformation/PersonalInformationSlice";
import { useEffect, useState } from "react";
import { purgeTimestamp } from "../features/expireStates/ExpireStatesSlice";
import { addReferralSource, getReferralSources } from "../api/General";
import { toast } from "react-toastify";
import { purgeReferral } from "../features/referral/ReferralSlice";

const Finish = ({ lang, reschedule }) => {
  const dispatch = useDispatch();
  // get referral
  const referral = useSelector((state) => state.referral);
  const [referralSources, setReferralSources] = useState([]);
  const [selectedReferalSource, setSelectedReferalSource] = useState("");
  const [referralText, setReferralText] = useState("");
  useEffect(() => {
    dispatch(purgeDoctors());
    dispatch(purgePackages());
    dispatch(purgeSlots());
    dispatch(purgePersonalInformation());
    dispatch(purgeTimestamp());
    if (referral.isNewPatient) {
      getReferralSources().then((res) => {
        setReferralSources(res.data);
      });
    }
  }, [dispatch]);

  const onSaveReferral = () => {
    if (selectedReferalSource === "") {
      toast.error("Va rugam sa selectati sursa de referinta!");
      return;
    }
    const data = {
      pacient_id: referral.patientId,
      source_id: selectedReferalSource,
      text: referralText !== null && referralText !== "" ? referralText : null,
    };
    addReferralSource(data).then((res) => {
      if (res.status === 200) {
        dispatch(purgeReferral());
      }
    });
  };

  return (
    <>
      <Header lang={lang} />
      <div>
        <SidebarDesktop lang={lang} />

        <main className="lg:pl-72 mt-16">
          <div className="px-4 sm:px-6 lg:px-8 py-20">
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm">
                <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                  {reschedule === true
                    ? lang === "ro"
                      ? "Anulare finalizata"
                      : "Appointment cancelled"
                    : lang === "ro"
                    ? "Va multumim pentru programarea efectuata!"
                    : "Thank you for your appointment!"}
                </h3>
              </div>
              {referral.isNewPatient === true ? (
                <div className="mt-8 text-center">
                  <h4 className="my-2.5 leading-7 text-xl font-semibold text-center">
                    {reschedule === true
                      ? lang === "ro"
                        ? "Anulare finalizata"
                        : "Appointment cancelled"
                      : lang === "ro"
                      ? "Ajutați-ne să știm cum ne-ați descoperit!"
                      : "Thank you for your appointment!"}
                  </h4>
                  <select
                    id="referral"
                    name="referral"
                    value={selectedReferalSource}
                    onChange={(e) => {
                      setSelectedReferalSource(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      );
                    }}
                    className="mt-4 block w-full lg:w-1/2 mx-auto rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-officialPrimary sm:text-sm lg:text-base sm:leading-6"
                  >
                    <option value={""}>Selectați sursa </option>
                    {referralSources.map((source) => (
                      <option key={source.id} value={source.id}>
                        {source.source}
                      </option>
                    ))}
                  </select>
                  <div
                    className={
                      referralSources.find(
                        (el) => el.id === selectedReferalSource
                      )?.additional_details === 1 &&
                      selectedReferalSource !== ""
                        ? ""
                        : "hidden"
                    }
                  >
                    <div className="mt-2">
                      <input
                        id="additional_details_referral"
                        name="additional_details_referral"
                        type="text"
                        value={referralText}
                        onChange={(e) => {
                          setReferralText(e.target.value);
                        }}
                        placeholder="Detalii suplimentare despre sursa"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>
                  <button
                    className="align-center mx-auto bg-secondary hover:bg-primary text-white px-4 py-1.5 rounded text-2xl mt-4"
                    onClick={() => onSaveReferral()}
                  >
                    {lang === "ro" ? "Salvează" : "Save"}
                  </button>
                </div>
              ) : null}
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Finish;
