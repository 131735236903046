import { useSelector } from "react-redux";

const SelectPackage = ({ onSelectPackage, lang }) => {
  const packages = useSelector((state) => state.packages);

  return (
    <div className="text-sm">
      <h3 className="my-2.5 leading-7 text-base text-black opacity-85 font-medium ">
        {lang === "ro"
          ? "Alegeți tipul de consultație sau pachetul de servicii care se potrivește cel mai bine nevoilor dumneavoastră:"
          : "Choose the type of consultation or service package that best suits your needs: "}
      </h3>
      <div className="flex my-2.5 flex-wrap">
        {packages.packages?.map((pack) => (
          <button
            key={pack.id}
            type="button"
            onClick={() => onSelectPackage(pack.id)}
            className={
              (packages.selectedPackageId === pack.id
                ? "bg-blue-500 text-white hover:bg-blue-400"
                : "text-inherit bg-white hover:text-blue-500") +
              " px-3.5 border  leading-7"
            }
          >
            {pack.name}
          </button>
        ))}
      </div>
      <div
        className="text-sm leading-normal my-2.5 opacity-65 text-black"
        dangerouslySetInnerHTML={{
          __html: packages.packages.find(
            (el) => el.id === packages.selectedPackageId
          )?.description,
        }}
      ></div>
    </div>
  );
};

export default SelectPackage;
