import { useSelector } from "react-redux";
import Loading from "../Loading";
import Select from "react-select";

const SelectDoctor = ({ onSelectMedicalUser, loadingDoctors, lang }) => {
  const doctors = useSelector((state) => state.doctors.value);
  const packages = useSelector((state) => state.packages);

  return (
    <div>
      <div className="mb-2.5">
        <label className="text-sm">
          {lang === "ro"
            ? "Opțional, alegeți medicul care va efectua consultația:"
            : "Optionally, choose the doctor who will conduct the consultation:"}
        </label>
      </div>
      {loadingDoctors === true ? (
        <div className="w-full lg:w-1/2 text-center justify-center flex">
          <Loading />
        </div>
      ) : (
        <div className="w-full lg:w-1/2">
          <Select
            className="basic-single z-20"
            classNamePrefix="select"
            placeholder={
              lang === "ro"
                ? "Selectați medicul din listă"
                : "Select your doctor from the list"
            }
            defaultValue={doctors.selectedDoctorId}
            value={
              doctors.selectedDoctorId !== null
                ? {
                    value: doctors.selectedDoctorId,
                    label:
                      doctors.doctors.find(
                        (el) => el.user_id === doctors.selectedDoctorId
                      )?.user_name +
                      " - " +
                      packages.packages.find(
                        (el) => el.id == packages.selectedPackageId
                      )?.name +
                      " - " +
                      parseInt(
                        doctors.doctors.find(
                          (el) => el.user_id === doctors.selectedDoctorId
                        )?.value
                      ) +
                      " lei",
                  }
                : null
            }
            isClearable={true}
            onChange={(e) => onSelectMedicalUser(e)}
            isSearchable={false}
            options={doctors.doctors.map((doctor) => ({
              value: doctor.user_id,
              label:
                doctor.user_name +
                " - " +
                packages.packages.find(
                  (el) => el.id == packages.selectedPackageId
                )?.name +
                " - " +
                parseInt(doctor.value) +
                " lei",
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default SelectDoctor;
