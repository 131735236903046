import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  setAddress,
  setCNP,
  setCity,
  setEmail,
  setFirstName,
  setLastName,
  setPhone,
  setRegion,
} from "../../features/personalInformation/PersonalInformationSlice";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { REGIONS } from "../../utils/Constants";

const PersonalDataFields = ({ lang, showCnpError }) => {
  const personalInformation = useSelector((state) => state.personalInformation);
  const dispatch = useDispatch();
  return (
    <>
      <div className="w-full lg:w-3/4 ml-0 lg:ml-16">
        <div className="grid grid-cols-9 gap-2 lg:gap-4">
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="firstName"
              className="block text-sm text-right justify-center align-center"
            >
              <span className="text-red-500 text-lg">*</span>
              {lang === "ro" ? "Prenume:" : "First Name:"}
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={personalInformation.firstName}
              onChange={(e) => dispatch(setFirstName(e.target.value))}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            />
          </div>
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="lastName"
              className="block text-sm text-right justify-center align-center"
            >
              <span className="text-red-500 text-lg">*</span>
              {lang === "ro" ? "Nume (de familie):" : "Last Name:"}
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={personalInformation.lastName}
              onChange={(e) => dispatch(setLastName(e.target.value))}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            />
          </div>
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="cnp"
              className="block text-sm text-right justify-center align-center"
            >
              <span className="text-red-500 text-lg">*</span>CNP:
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            <input
              type="text"
              id="cnp"
              value={personalInformation.CNP}
              onChange={(e) => dispatch(setCNP(e.target.value))}
              name="cnp"
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            />
          </div>
          {showCnpError ? (
            <div className="col-span-9 lg:col-span-7 lg:col-start-3 text-red-600">
              {lang === "ro"
                ? "Daca nu aveti un CNP valid, va rugam sa ne contactati la numarul de telefon 021 9102."
                : "If you do not have a valid CNP, please contact us at the phone number 021 9102."}
            </div>
          ) : null}
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="first_name"
              className="block text-sm text-right justify-center align-center"
            >
              <span className="text-red-500 text-lg">*</span>E-mail:
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            <input
              type="email"
              id="email"
              name="email"
              value={personalInformation.email}
              onChange={(e) => dispatch(setEmail(e.target.value))}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            />
          </div>
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="phone"
              className="block text-sm text-right justify-center align-center"
            >
              <span className="text-red-500 text-lg">*</span>{" "}
              {lang === "ro" ? "Telefon:" : "Mobile Phone:"}
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7 w-full lg:w-4/5">
            <PhoneInput
              country={"ro"}
              inputStyle={{
                width: "100%",
                height: "34px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              preferredCountries={["ro"]}
              countryCodeEditable={false}
              inputProps={{
                name: "phone",
                required: true,
              }}
              value={personalInformation.phone}
              onChange={(value, country, e, formattedValue) => {
                dispatch(
                  setPhone({
                    phone: formattedValue,
                    countryCode: country.countryCode,
                  })
                );
              }}
            />
            {personalInformation.countryCodePhone === "ro" &&
            personalInformation.phone?.match(/\d/g)?.length > 11 ? (
              <>
                <span className="text-red-600 align-text-bottom	">
                  <XCircleIcon className="h-4 w-4 inline-block" />
                </span>
                <span className="text-red-600">
                  {lang === "ro"
                    ? "Numărul de telefon introdus este incorect. Acesta trebuie să conțină exact 9 cifre, în afară de prefixul internațional (+40)."
                    : "The phone number you entered is incorrect. It must contain exactly 9 digits, excluding the international prefix (+40)."}
                </span>
              </>
            ) : null}
          </div>
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="region"
              className="block text-sm text-right justify-center align-center"
            >
              <span className="text-red-500 text-lg">*</span>{" "}
              {lang === "ro" ? "Județ / Sector:" : "County / Sector:"}
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            {/* <input
              type="text"
              id="region"
              name="region"
              value={personalInformation.region}
              onChange={(e) => dispatch(setRegion(e.target.value))}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            /> */}
            <select
              id="area_id"
              name="area_id"
              onChange={(e) => dispatch(setRegion(e.target.value))}
              value={personalInformation.region}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            >
              <option value="" hidden>
                Selectează
              </option>
              {REGIONS.map((region) => {
                return (
                  <option value={region.id} key={region.id}>
                    {region.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="city"
              className="block text-sm text-right justify-center align-center"
            >
              {lang === "ro" ? "Oraș:" : "City:"}
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            <input
              type="text"
              id="city"
              name="city"
              value={personalInformation.city}
              onChange={(e) => dispatch(setCity(e.target.value))}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            />
          </div>
          <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
            <label
              htmlFor="address"
              className="block text-sm text-right justify-center align-center"
            >
              {lang === "ro" ? "Adresa:" : "Address:"}
            </label>
          </div>
          <div className="col-span-9 lg:col-span-7">
            <input
              type="text"
              id="address"
              name="address"
              value={personalInformation.address}
              onChange={(e) => dispatch(setAddress(e.target.value))}
              className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDataFields;
