import { createSlice } from "@reduxjs/toolkit";

export const ExpireStatesSlice = createSlice({
  name: "expireStates",
  initialState: {
    timestamp: null,
  },
  reducers: {
    setTimestamp: (state, action) => {
      return {
        ...state,
        timestamp: action.payload,
      };
    },
    purgeTimestamp: (state) => {
      return {
        ...state,
        timestamp: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTimestamp, purgeTimestamp } = ExpireStatesSlice.actions;

export default ExpireStatesSlice.reducer;
