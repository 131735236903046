import { useDispatch } from "react-redux";
import Header from "../components/Header";
import SidebarDesktop from "../components/SidebarDesktop";
import { purgeDoctors } from "../features/doctors/DoctorsSlice";
import { purgePackages } from "../features/packages/PackagesSlice";
import { purgeSlots } from "../features/slots/SlotsSlice";
import { purgePersonalInformation } from "../features/personalInformation/PersonalInformationSlice";
import { useEffect } from "react";
import {
  purgeTimestamp,
  setTimestamp,
} from "../features/expireStates/ExpireStatesSlice";
import { Link, useParams } from "react-router-dom";
import { getBooking } from "../api/General";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import CancelModal from "../components/CancelModal";
import { useState } from "react";
import Loading from "../components/Loading";
import moment from "moment-timezone";

const Cancel = ({ lang }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState({});
  const [cancelled, setCancelled] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTimestamp(new Date().getTime()));
    dispatch(purgeDoctors());
    dispatch(purgePackages());
    dispatch(purgeSlots());
    dispatch(purgePersonalInformation());
    getBooking(id).then((res) => {
      if (res.status == 200) {
        setBooking(res.data);
        if (res.data.cancelled === 1) {
          setCancelled(true);
        }
      } else if (res.status === 404) {
        setNotFound(true);
      }
      setLoading(false);
    });
  }, []);
  return (
    <>
      <Header lang={lang} />
      <CancelModal
        lang={lang}
        open={open}
        setOpen={setOpen}
        booking={booking}
      />
      <div>
        <SidebarDesktop lang={lang} />

        <main className="lg:pl-72 mt-16">
          <div className="px-4 sm:px-6 lg:px-8 py-20">
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm align-center text-center">
                {loading === true ? (
                  <div className="text-center mx-auto flex justify-center">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {notFound === true ? (
                      <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                        {lang === "ro"
                          ? "Programarea nu a fost găsită"
                          : "Appointment not found"}
                      </h3>
                    ) : (
                      <>
                        {cancelled === true ? (
                          <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                            {lang === "ro"
                              ? "Programarea a fost deja anulată"
                              : "The appointment has already been cancelled"}
                          </h3>
                        ) : (
                          <>
                            <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                              {lang === "ro"
                                ? "Anulare programare"
                                : "Cancel appointment"}
                            </h3>
                            <p className="text-xl text-center my-10">
                              {lang === "ro" ? (
                                <>
                                  Ne pare rău că nu puteți ajunge la programarea
                                  din data de:{" "}
                                  <strong>
                                    {moment
                                      .utc(booking?.programat)
                                      .tz("Europe/Bucharest")
                                      .locale("ro")
                                      .format(
                                        "dddd, DD MMMM YYYY, [ora] HH:mm"
                                      )}
                                  </strong>
                                  , pe numele{" "}
                                  <strong>
                                    {booking?.pacient_first_name}{" "}
                                    {booking?.pacient_last_name}
                                  </strong>
                                  . Vă rugăm să selectați o opțiune pentru
                                  reprogramare:
                                </>
                              ) : (
                                <>
                                  We are sorry to hear that you wish to cancel
                                  your appointment. We understand that
                                  unforeseen circumstances can sometimes prevent
                                  you from keeping your scheduled time. If you
                                  would like, we can <strong>reschedule</strong>{" "}
                                  for another date.
                                </>
                              )}
                            </p>
                            <Link
                              to={
                                lang === "ro"
                                  ? "/rescheduling/" + id + "/dashboard"
                                  : "/en/rescheduling/" + id + "/dashboard"
                              }
                              className="align-center mx-auto bg-secondary hover:bg-primary text-white px-4 py-1.5 rounded text-2xl"
                            >
                              {lang === "ro"
                                ? "Reprogramează-te"
                                : "Reschedule"}
                            </Link>
                            <div className="flex justify-center md:justify-end mt-8 md:mt-4">
                              <button
                                className="text-gray-600 text-sm flex align-center items-center gap-1 hover:bg-gray-100 rounded px-2 py-1 hover:underline"
                                onClick={() => setOpen(true)}
                              >
                                {lang === "ro"
                                  ? "Continua anularea"
                                  : "Continue canceling"}
                                <ArrowRightIcon className="w-4 h-4 text-gray-600" />
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Cancel;
