import axios from "axios";
import AESDecryptor from "../utils/AESDecryptor";
import AESEncryptor from "../utils/AESEncrypt";

export const getAllPackages = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/packages`);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getMedicalUsers = async (package_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/slots/users`,
      {
        params: {
          package_id: package_id,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getCheckInSummary = async (booking_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/bookings/checkin-summary/${booking_id}`,
      {
        params: {},
      }
    );
    const data = res.data;
    const tokenC = data.ciphertext;
    const tokenI = data.iv;
    if (tokenC && tokenI) {
      const decObj = {
        ciphertext: tokenC,
        iv: tokenI,
      };
      const result = AESDecryptor(decObj, process.env.REACT_APP_ENCRYPT_KEY);
      if (result != null) {
        console.log(JSON.parse(result));
        return { status: 200, data: JSON.parse(result) };
      }
    }
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getSlots = async (package_id, user_id = null) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/slots`, {
      params: {
        package_id: package_id,
        user_id: user_id,
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const createAppointment = async (data) => {
  try {
    const encryptedData = AESEncryptor(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPT_KEY
    );
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/appointments`,
      encryptedData
    );

    return {
      status: res.status,
      data: JSON.parse(
        AESDecryptor(
          {
            ciphertext: res.data.ciphertext,
            iv: res.data.iv,
          },
          process.env.REACT_APP_ENCRYPT_KEY
        )
      ),
    };
  } catch (err) {
    return err.response;
  }
};

export const getBooking = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/bookings/${id}`,
      {
        params: {},
      }
    );
    const data = res.data;
    const tokenC = data.ciphertext;
    const tokenI = data.iv;
    if (tokenC && tokenI) {
      const decObj = {
        ciphertext: tokenC,
        iv: tokenI,
      };
      const result = AESDecryptor(decObj, process.env.REACT_APP_ENCRYPT_KEY);
      if (result != null) {
        return { status: 200, data: JSON.parse(result) };
      }
    }
    return res;
  } catch (err) {
    return err.response;
  }
};

export const cancelBooking = async (id) => {
  try {
    const encryptedData = AESEncryptor(
      JSON.stringify({
        uuid: id,
      }),
      process.env.REACT_APP_ENCRYPT_KEY
    );
    const res = await axios.put(
      `${process.env.REACT_APP_DEV_HOST}/bookings/cancel`,
      encryptedData
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const patientCheckIn = async (data) => {
  try {
    const encryptedData = AESEncryptor(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPT_KEY
    );
    const res = await axios.put(
      `${process.env.REACT_APP_DEV_HOST}/bookings/checkin`,
      encryptedData
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const rescheduleBooing = async (data) => {
  try {
    const encryptedData = AESEncryptor(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPT_KEY
    );
    const res = await axios.put(
      `${process.env.REACT_APP_DEV_HOST}/bookings/reschedule`,
      encryptedData
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getReferralSources = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/referral_sources`,
      {
        params: {},
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const addReferralSource = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/pacient_referral_sources`,
      data
    );

    return res;
  } catch (err) {
    return err.response;
  }
};
