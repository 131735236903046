import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../features/personalInformation/PersonalInformationSlice";

const AdditionalInformation = ({ lang }) => {
  const dispatch = useDispatch();
  const personalInformation = useSelector((state) => state.personalInformation);
  return (
    <div>
      <div className="mb-2 text-sm">
        <label>
          {lang === "ro" ? "Observații suplimentare" : "Additional information"}
        </label>
      </div>
      <div>
        <textarea
          className="w-full border border-gray-300 px-2.5 py-1 rounded-md text-sm"
          rows={3}
          value={personalInformation.message}
          onChange={(e) => {
            dispatch(setMessage(e.target.value));
          }}
          placeholder={
            lang === "ro"
              ? "Introduceți alte informații concludente legate de această programare"
              : "Enter other conclusive information about this appointment"
          }
        ></textarea>
      </div>
    </div>
  );
};

export default AdditionalInformation;
