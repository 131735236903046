import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { cancelBooking } from "../api/General";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";

const CancelModal = ({ open, setOpen, booking, lang }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const cancelBookingClick = (e) => {
    const result = cancelBooking(id).then((res) => {
      if (res.status === 200) {
        setOpen(false);
        navigate("/cancelled");
      }
    });
    toast.promise(
      result,
      {
        pending: "Anulare programare...",
        success: "Programare anulata!",
        error: "Eroare la anulare programare!",
      },
      {
        style: {
          minWidth: "250px",
        },
      }
    );
  };

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Anulare programare
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="mt-2">
                        <p className="text-md text-gray-500">
                          Sunteti sigur ca doriti sa anulati programarea din
                          data de:{" "}
                          <strong>
                            {moment
                              .utc(booking?.programat)
                              .tz("Europe/Bucharest")
                              .locale("ro")
                              .format("dddd, DD MMMM YYYY, HH:mm")}
                          </strong>{" "}
                          pe numele{" "}
                          <strong>
                            {booking?.pacient_first_name}{" "}
                            {booking?.pacient_last_name}
                          </strong>{" "}
                          ?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={cancelBookingClick}
                  >
                    Anulează programarea
                  </button>
                  <Link
                    to={
                      lang === "ro"
                        ? "/rescheduling/" + id + "/dashboard"
                        : "/en/rescheduling/" + id + "/dashboard"
                    }
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  >
                    {lang === "ro" ? "Reprogramează-te" : "Reschedule"}
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CancelModal;
