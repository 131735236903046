import { createSlice } from "@reduxjs/toolkit";

export const referralSlice = createSlice({
  name: "personalInformation",
  initialState: {
    bookingId: null,
    isNewPatient: false,
    patientId: null,
  },
  reducers: {
    setReferral: (state, action) => {
      return {
        ...state,
        bookingId: action.payload.bookingId,
        isNewPatient: action.payload.isNewPatient,
        patientId: action.payload.patientId,
      };
    },
    purgeReferral: (state) => {
      return {
        ...state,
        bookingId: null,
        isNewPatient: false,
        patientId: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReferral, purgeReferral } = referralSlice.actions;

export default referralSlice.reducer;
