const PersonalDataAbstract = ({ lang }) => {
  return (
    <>
      <h3 className="my-2.5 leading-7 text-lg font-semibold">
        {lang === "ro" ? "Date Personale" : "Personal data"}
      </h3>
      <div className="text-sm leading-normal my-2.5">
        <p className="mb-3.5 text-gray-600">
          {lang === "ro" ? (
            <>
              <span>
                Vă rugăm să introduceţi datele pacientului pentru care se face
                programarea. Toate datele dvs. sunt confidențiale, securizate și
                protejate. Datele personale sunt prelucrate conform
                REGULAMENTULUI (UE) 2016/679 AL PARLAMENTULUI EUROPEAN ȘI AL
                CONSILIULUI din 27 aprilie 2016 privind protecția persoanelor
                fizice în ceea ce privește prelucrarea datelor cu caracter
                personal, precum și a legilor române în vigoare. Datele pot fi
                dezvăluite unor terţi în baza unui temei legal justificat. Vă
                puteţi exercita drepturile menționate în{" "}
                <a
                  href="https://infosan.ro/politica-de-confidentialitate/"
                  target="_blank"
                  className="text-blue-500"
                >
                  {" "}
                  Politica noastră de confidențialitate
                </a>{" "}
                în condiţiile prevăzute de Regulamentului (UE) 2016/679 al
                Parlamentului European și al Consiliului din 27 aprilie 2016
                privind protecția persoanelor fizice în ceea ce privește
                prelucrarea datelor cu caracter personal ("GDPR") și Legea nr.
                190 din 18 iulie 2018 privind măsuri de punere în aplicare a
                Regulamentului (UE) 2016/679 al Parlamentului European și al
                Consiliului din 27 aprilie 2016 privind protecția persoanelor
                fizice în ceea ce privește prelucrarea datelor cu caracter
                personal și privind libera circulație a acestor date și de
                abrogare a Directivei 95/46/CE (Regulamentul general privind
                protecția datelor), printr-o cerere scrisă, trimisă la oricare
                din datele de contact ale Societății, prevăzute în Secțiunea 2
                din
              </span>
              <a
                href="https://infosan.ro/politica-de-confidentialitate/"
                target="_blank"
                className="text-blue-500"
              >
                {" "}
                Politica noastră de confidențialitate.
              </a>
            </>
          ) : (
            <span>
              Personal data is processed in accordance with Regulation (EU)
              2016/679 of the European Parliament and of the Council of 27 April
              2016 on the protection of individuals with regard to the
              processing of personal data and the protection of individuals with
              regard to the processing of personal data with respect to the
              processing of personal data, as well as of the Romanian laws in
              force, the data may be disclosed to third parties on a reasonably
              justified basis. You can exercise the rights mentioned in our {" "}
              <a
                href="https://infosan.ro/politica-de-confidentialitate/"
                target="_blank"
                className="text-blue-500"
              >
                {" "}
                Privacy Policy
              </a>{" "}
              under the conditions provided by Regulation (EU) 2016/679 of the
              European Parliament and of the Council of 27 April 2016 on the
              protection of natural persons with regard to the processing of
              personal data ("GDPR") and Law no. 190 of 18 July 2018 on measures
              to implement Regulation (EU) 2016/679 of the European Parliament
              and of the Council of 27 April 2016 on the protection of natural
              persons with regard to the processing of personal data and on the
              free movement of such data and repealing Directive 95/46/EC
              (General Data Protection Regulation), through a written request
              sent to any of the contact details of the Company, provided in
              Section 2 of our {" "}
              <a
                href="https://infosan.ro/politica-de-confidentialitate/"
                target="_blank"
                className="text-blue-500"
              >
                {" "}
                Privacy Policy
              </a>
              .
            </span>
          )}
        </p>
      </div>
    </>
  );
};

export default PersonalDataAbstract;
